<template>
  <div :class="headerClasses">
    <div
      v-if="isMainPage"
      class="sm-header__left-section"
    >
      <sm-header-menu
        v-if="menuItems.length"
        :items="menuItems"
      ></sm-header-menu>
    </div>
    <div
      v-else
      class="sm-header__left-section"
    >
      <div class="sm-header__left-content">
        <div class="sm-header__line">
          <sm-breadcrumbs :items="breadcrumbs"></sm-breadcrumbs>
        </div>
        <div class="sm-header__line">
          <a
            class="sm-header__back-button"
            @click.prevent="handleBackClick"
          >
            <sm-icon
              name="ChevronLeft"
              class="sm-header__icon"
              :size="24"
            />
          </a>
          <p class="sm-header__page-title">{{ pageTitle }}</p>
        </div>
      </div>
    </div>
    <div
      class="sm-header__profile"
      v-click-outside="handleClickOutside"
    >
      <div
        :class="profileIconWrapperClasses"
        @click="toggleDropdown"
      >
        <div class="sm-header__icon-container">
          <div class="sm-header__icon-white-border">
            <sm-icon name="User" />
          </div>
        </div>
      </div>
      <sm-profile-dropdown
        v-if="dropdownOpen"
        :userName="userName"
        :theme="theme"
        @logout="handleLogout"
      >
        <slot></slot>
      </sm-profile-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Vue
import { computed, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmHeaderMenu from '@/components/layouts/header/SmHeaderMenu.vue';
import SmProfileDropdown from '@/components/layouts/header/SmProfileDropdown.vue';
import SmBreadcrumbs from '@/components/common/SmBreadcrumbs/SmBreadcrumbs.vue';

// Types
import { IHeaderProps } from '@/types/components/layout';
import { IBreadcrumbItem } from '@/types/components/breadcrumbs';
import { Class } from '@/types/common';

const props = withDefaults(
  defineProps<IHeaderProps>(),
  {
    userName: '',
    menuItems: () => [],
    isMainPage: false,
    theme: 'light'
  }
);

const { menuItems, userName, isMainPage, theme } = toRefs(props);

// Emits
const emits = defineEmits<{
  (e: 'logout'): void;
}>();

const route = useRoute();
const router = useRouter();
const dropdownOpen = ref<boolean>(false);

const breadcrumbs = computed((): IBreadcrumbItem[] => {
  return route.matched.map((route) => {
    const title: string = route.meta.title as string;
    return {
      title,
      route: {
        name: route.name
      }
    };
  });
});

const pageTitle = computed(() => route.meta.title || '');

const headerClasses = computed((): Class => ({
  'sm-header': true,
  'sm-header--page-title-visible': !isMainPage,
}));
const profileIconWrapperClasses = computed((): Class => ({
  'sm-header__profile-icon-wrapper': true,
  'sm-header__profile-icon-wrapper--active': dropdownOpen.value,
}));

// Methods
const toggleDropdown = (): void => {
  dropdownOpen.value = !dropdownOpen.value;
};

const handleClickOutside = (): void => {
  dropdownOpen.value = false;
};

const handleBackClick = (): void => {
  router.back();
}

const handleLogout = (): void => emits('logout');

</script>

<style lang="scss">
.sm-header {
  box-sizing: border-box;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
  padding: 0 var(--padding);

  @include mini;
  
  background-color: var(--Background);
  border-bottom: 1px solid var(--BorderMinor);
}

.sm-header--page-title-visible {
  height: 88px;
}

.sm-header__left-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.sm-header__left-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
}

.sm-header__line {
  display: flex;
  align-items: center;
}

.sm-header__address {
  margin: 0;

  @include micro;

  color: var(--Icon);
}

.sm-header__page-title {
  margin: 0;

  @include h2;
}

.sm-header__profile {
  position: relative;
}

.sm-header__icon-white-border {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;

  background-color: var(--SecContainer);
  border-radius: 50%;
  border: 2px solid var(--Background);
}

.sm-header__profile-icon-wrapper {
  position: relative;
  
  display: flex;
  align-items: center;
  
  cursor: pointer;
}

.sm-header__icon-container {
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  background-color: var(--Secondary);
  border-radius: 50%;
  border: 2px solid var(--Background);

  &::after {
    content: '';
    
    position: absolute;
    right: 0;
    bottom: 0;
    
    width: 0;
    height: 0;
    
    border-left: 6px solid transparent;
    border-bottom: 6px solid var(--Secondary);
  }
}

.sm-header__profile-icon-wrapper--active .sm-header__icon-container {
  border: 2px solid var(--Body);

  &::after {
    border-bottom: 6px solid var(--Body);
  }
}

.sm-header__back-button {
  display: flex;
  align-items: center;
  
  text-decoration: none;
}

.sm-header__icon {
  color: var(--Icon);
}
</style>
