<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1156_20785)">
      <rect width="40" height="40" rx="20" fill="#DEE4E4"/>
      <g clip-path="url(#clip1_1156_20785)">
        <path d="M20 47.4929C24.1382 47.4929 27.4929 44.1382 27.4929 40C27.4929 35.8618 24.1382 32.5071 20 32.5071C15.8618 32.5071 12.5071 35.8618 12.5071 40C12.5071 44.1382 15.8618 47.4929 20 47.4929Z" fill="#C24611"/>
        <path d="M20 28V29.8268" stroke="#C24611" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M11.4998 31.5002L12.8063 32.8064" stroke="#C24611" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M4.98242 40H9.82682" stroke="#C24611" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M35.0174 40H30.1731" stroke="#C24611" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M28.5002 31.4998L27.1936 32.8065" stroke="#C24611" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1156_20785">
        <rect width="40" height="40" rx="20" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1156_20785">
        <rect width="32" height="32" fill="white" transform="translate(4 24)"/>
      </clipPath>
    </defs>
  </svg>
</template>