<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1156_20764)">
      <rect width="40" height="40" rx="20" fill="#262644"/>
      <g clip-path="url(#clip1_1156_20764)">
        <path d="M18.8081 32.8487C18.8081 30.466 19.5012 28.246 20.6955 26.3813C21.069 25.7893 20.6709 25 19.973 25C19.9681 25 19.9632 25 19.9583 25C13.0969 25 7.58717 30.7965 8.02461 37.772C8.38832 43.608 13.0232 48.4129 18.8278 48.9507C22.342 49.2763 25.5761 48.0676 27.9501 45.9217C28.4661 45.458 28.245 44.5996 27.5765 44.4171C22.519 43.016 18.8032 38.369 18.8032 32.8487H18.8081Z" fill="#FFF38E"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1156_20764">
        <rect width="40" height="40" rx="20" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1156_20764">
      <rect width="24" height="24" fill="white" transform="translate(8 25)"/>
      </clipPath>
    </defs>
  </svg>
</template>