<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#262644"/>
    <g clip-path="url(#clip0_1018_12157)">
      <path d="M18.8081 15.8487C18.8081 13.466 19.5012 11.246 20.6955 9.38129C21.069 8.78931 20.6709 8 19.973 8C19.9681 8 19.9632 8 19.9583 8C13.0969 8 7.58717 13.7965 8.02461 20.772C8.38832 26.608 13.0232 31.4129 18.8278 31.9507C22.342 32.2763 25.5761 31.0676 27.9501 28.9217C28.4661 28.458 28.245 27.5996 27.5765 27.4171C22.519 26.016 18.8032 21.369 18.8032 15.8487H18.8081Z" fill="#FFF38E"/>
      <path d="M30.6091 14.6302C27.2079 15.0742 26.2446 16.0411 25.8022 19.4549C25.3599 16.0411 24.3965 15.0742 20.9954 14.6302C24.3965 14.1862 25.3599 13.2193 25.8022 9.80554C26.2446 13.2193 27.2079 14.1862 30.6091 14.6302Z" fill="#FFF38E"/>
      <path d="M32 21.8672C30.2994 22.0892 29.8177 22.5727 29.5966 24.2796C29.3754 22.5727 28.8937 22.0892 27.1931 21.8672C28.8937 21.6452 29.3754 21.1618 29.5966 19.4549C29.8177 21.1618 30.2994 21.6452 32 21.8672Z" fill="#FFF38E"/>
    </g>
    <defs>
      <clipPath id="clip0_1018_12157">
      <rect width="24" height="24" fill="white" transform="translate(8 8)"/>
      </clipPath>
    </defs>
  </svg>
</template>