<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1158_21103)">
      <rect width="40" height="40" rx="20" fill="#7171A7"/>
    </g>
    <defs>
      <clipPath id="clip0_1158_21103">
        <rect width="40" height="40" rx="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>