<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#DEE4E4"/>
    <g clip-path="url(#clip0_1018_12159)">
      <path d="M20 27.4929C24.1382 27.4929 27.4929 24.1383 27.4929 20C27.4929 15.8618 24.1382 12.5071 20 12.5071C15.8618 12.5071 12.5071 15.8618 12.5071 20C12.5071 24.1383 15.8618 27.4929 20 27.4929Z" fill="#DDA411"/>
      <path d="M20 4.98248V9.82688" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M9.38086 9.38104L12.8063 12.8065" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M4.98242 20H9.82682" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M9.38086 30.619L12.8063 27.1936" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M20 35.0176V30.1733" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M30.619 30.619L27.1936 27.1936" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M35.0174 20H30.1731" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M30.619 9.38104L27.1936 12.8065" stroke="#DDA411" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1018_12159">
        <rect width="32" height="32" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
</template>