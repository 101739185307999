import { computed, ComputedRef } from 'vue';
import { setItemToLocalStorage, getItemFromLocalStorage } from '@/utils/persistenceStorage';
import cssColorVariables from "@/assets/cssColorVariables";

interface IUseTheme {
  appTheme: ComputedRef<string>;
  colors: ComputedRef<Record<PropertyKey, string>>;
  toggleAppTheme: () => void;
}

/**
 * Класс для работы с цветами темы
 *
 * @return  {IUseTheme}  Публичные вычисляемые переменные и методы
 */
export function useTheme(): IUseTheme {
  /**
   * Текущая тема
  *
  * @return  {<string>} Название темы
  */
  // TODO: theme computed (from localStorage)
  const appTheme = computed((): string => {
    // TODO: ЗАпрос в стор на получение темы
    try {
      const currentTheme: string = getItemFromLocalStorage('appTheme') || '';
      return currentTheme || 'light';
    } catch (error) {
      console.error('Не удалось получить тему из localStorage:', error);
      return 'light';
    }
  });


  /**
   * Базовые цвета
   *
   * @return  {Recorc<PropertyKey, string>}  Объект с переменными цвета, 
   * которые разбиты на группы: темная и светлая темы
   */
  const baseColors = computed((): Record<PropertyKey, string> => (
    (cssColorVariables as any)[appTheme.value]
  ));

  /**
   * Переменные цвета пришедшие из конфига приложения
  *
  * @return  {Record<PropertyKey, string>}  [return description]
  */
  // TODO: customColors computed (from API)
  const customColors = computed((): Record<PropertyKey, string> => (
    {}
  ));

  /**
   * Скомпилированные и собранные переменные цвета
   *
   * @return  {Record<PropertyKey, string>} Объект цветов
   */
  const colors = computed((): Record<PropertyKey, string> => ({
    ...baseColors.value,
    ...customColors.value
  }));

  /**
   * Переключатель темы
   * 
   * @returns {void}
   */
  const toggleAppTheme = (): void => {
    // FIXME: Переключать тему через store
    
    const theme = appTheme.value === 'light'
      ? 'dark'
      : 'light';
    
    setItemToLocalStorage('appTheme', theme);

    window.location.reload();
  }

  return {
    appTheme,
    colors,
    toggleAppTheme
  }
}