
<template>
  <div class="sm-theme-switcher" @click="toggleTheme">
    <div class="sm-theme-switcher__slider">
      <!-- Анимационная группа для иконок, при смене темы срабатывает анимация -->
      <transition-group 
        name="fade"
        tag="div"
        class="sm-theme-switcher__slider-container"
        @after-enter="handleAfterEnter"
      >
        <!-- Отображение иконок в зависимости от текущей темы -->
        <component 
          v-for="(icon, index) in visibleImages" 
          :key="index" 
          :is="icon" 
          class="sm-theme-switcher__icon sm-theme-switcher__fade-in" 
        />
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Vue Composition API
import { ref, computed, toRefs } from 'vue';

// Icon Components
import Sun from '@/assets/icons/Sun.vue';
import HalfOfSun from '@/assets/icons/HalfOfSun.vue';
import Moon from '@/assets/icons/Moon.vue';
import HalfOfMoon from '@/assets/icons/HalfOfMoon.vue';
import EmptySky from '@/assets/icons/EmptySky.vue';

// Props
const props = withDefaults(defineProps<{ appTheme: string }>(), {
  appTheme: 'light' 
});

const { appTheme } = toRefs(props);

const emits = defineEmits<{
  (e: 'toggleTheme'): void;
}>();

// Состояние текущей темы
const isLightTheme = ref<boolean>(appTheme.value === 'light');

// Последовательности иконок для светлой и темной тем
const lightSequence = [EmptySky, HalfOfSun, Sun];
const darkSequence = [EmptySky, HalfOfMoon, Moon];

// Определение текущей последовательности иконок в зависимости от темы
const currentSequence = computed(() => {
  return isLightTheme.value ? darkSequence : lightSequence;
});

// Начальное отображение последней иконки в последовательности (для плавности анимации)
const visibleImages = ref([currentSequence.value[2]]); 

// Methods
const toggleTheme = (): void => {
  isLightTheme.value = !isLightTheme.value;

  currentSequence.value.forEach((icon, index) => {
    setTimeout(() => {
      visibleImages.value = [icon];
    }, index * 300); 
  });
};

// Метод, который вызывается после завершения анимации ухода иконки
const handleAfterEnter = (): void => {
  emits('toggleTheme');
};
</script>

<style lang="scss" scoped>
.sm-theme-switcher {
  position: fixed;
  bottom: var(--padding);
  right: var(--padding);
  cursor: pointer;
  z-index: 1000;
}

.sm-theme-switcher__slider {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.sm-theme-switcher__slider-container {
  width: 100%;
  height: 100%;
}

.sm-theme-switcher__fade-in {
  @include transition(('fade'));
}
</style>
